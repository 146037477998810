import logo_white from "../../image/logo_white.png";
import cream_logo_white from "../../image/cream_logo_white.png";

const Footer = () => {
  const moveUrl = (url) => {
    // 새탭 열기로 사이트 이동
    window.open(url);
  };

  const openMail = (mail) => {
    window.location.href = `mailto:${mail}`;
  };

  return (
    <div className='flex w-full Footer py-[60px] bg-charcoal-500'>
      <div className='w-[1128px] flex flex-col mx-auto'>
        <div className='flex'>
          <img
            src={cream_logo_white}
            alt=''
            className='mr-10'
          />
          <img
            src={logo_white}
            alt=''
          />
        </div>

        <div className='flex mt-10 tracking-wider text-white '>
          <div className='flex flex-col space-y-2'>
            <div className='flex items-center '>
              <div className='w-[648px]'>상호명 : 주식회사 크림</div>
              <button
                className='text-sm underline underline-offset-1'
                onClick={() => moveUrl("https://www.creamcorp.co.kr/")}>
                회사소개
              </button>
            </div>
            <div className='flex items-center '>
              <div className='w-[648px]'>대표자명 : 김지성</div>
              <button
                className='text-sm underline underline-offset-1'
                onClick={() => moveUrl("https://www.creamcorp.co.kr/contact")}>
                제휴제안
              </button>
            </div>
            <div className='flex items-center '>
              <div className='w-[648px]'>사업자등록번호 : 862-86-01982</div>
              <button
                className='text-sm underline underline-offset-1'
                onClick={() => moveUrl("https://www.creamcorp.co.kr/이용약관")}>
                이용약관
              </button>
            </div>
            <div className='flex items-center '>
              <div className='w-[648px]'>
                본사 : 경상북도 포항시 남구 청암로 87, 545호
              </div>
              <button
                className='text-sm underline underline-offset-1'
                onClick={() =>
                  moveUrl("https://www.creamcorp.co.kr/개인정보처리방침")
                }>
                개인정보처리방침
              </button>
            </div>
            <div className='flex items-center '>
              <div className='w-[648px]'>
                사업장주소 : 서울 서초구 반포대로30길 81 웅진타워 1219호
              </div>
              <button
                className='text-sm underline underline-offset-1'
                onClick={() => openMail("contact@creamcorp.co.kr")}>
                contact@cream.corp
              </button>
            </div>
            <div className='flex items-center '>
              <div className='w-[648px]'>전화번호 : 070-4138-0272</div>
            </div>
          </div>
        </div>

        <div className='mt-10 text-white'>©2023 by Cream.</div>
      </div>
    </div>
  );
};

export default Footer;

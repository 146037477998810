import React, { useEffect, useState } from "react";
import MediaBox from "./MediaBox";
import Introduce from "./Introduce";
import About from "./About";
import HowToUse from "./HowToUse";
import Plan from "./Plan";
import FooterBanner from "./FooterBanner";
import Renewal from "./Renewal-241017";

const BodyFrame = () => {
  return (
    <div className='flex flex-col BodyFrame'>
      <MediaBox />
      <Renewal />
      {/* <MediaBox />
      <Introduce />
      <About />
      <HowToUse />
      <Plan />
      <FooterBanner /> */}
    </div>
  );
};

export default BodyFrame;

import React, { useRef, useState, useEffect } from "react";

import logo from "../../image/logo_text_white.png";
import new_logo from "../../image/240827_new logo.png";
import arrow from "../../image/arrow_forward_24px_black.png";

import ContactModal from "./ContactModal";

const MediaBox = () => {
  const videoRef = useRef(null);
  const videoBoxRef = useRef(null);
  const videoInnerBoxRef = useRef(null);
  const videoInnerBoxOverlayRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const moveUrl = (url) => {
    // 새탭 열기로 사이트 이동
    window.open(url);
  };

  useEffect(() => {
    let currentWidth = window.innerWidth - 16.32;
    if (currentWidth < 1128) {
      currentWidth = 1128;
    }
    videoRef.current.style.width = `${currentWidth}px`;
    videoBoxRef.current.style.width = `${currentWidth}px`;
    videoBoxRef.current.style.paddingBottom = `${(currentWidth * 9) / 16}px`;
    videoInnerBoxRef.current.style.width = `${currentWidth}px`;
    videoInnerBoxOverlayRef.current.style.width = `${currentWidth}px`;
    videoInnerBoxOverlayRef.current.style.height = `${
      (currentWidth * 9) / 16
    }px`;
    const padding = ((currentWidth * 9) / 16 - 250) / 2;
    videoInnerBoxRef.current.style.paddingTop = `${padding}px`;
    videoInnerBoxRef.current.style.paddingBottom = `${padding}px`;
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      let currentWidth = window.innerWidth - 16.32;
      // if (currentWidth < 1128) {
      //   currentWidth = 1128;
      // }
      videoRef.current.style.width = `${currentWidth}px`;
      videoBoxRef.current.style.width = `${currentWidth}px`;
      videoBoxRef.current.style.paddingBottom = `${(currentWidth * 9) / 16}px`;
      videoInnerBoxRef.current.style.width = `${currentWidth}px`;

      videoInnerBoxOverlayRef.current.style.width = `${currentWidth}px`;
      videoInnerBoxOverlayRef.current.style.height = `${
        (currentWidth * 9) / 16
      }px`;

      const padding = ((currentWidth * 9) / 16 - 250) / 2;
      videoInnerBoxRef.current.style.paddingTop = `${padding}px`;
      videoInnerBoxRef.current.style.paddingBottom = `${padding}px`;
    });

    return () => {
      window.removeEventListener("resize", () => {
        console.log("remove");
      });
    };
  });

  return (
    <div className='flex flex-col justify-center MediaBox'>
      <div ref={videoBoxRef}>
        <video
          ref={videoRef}
          className='absolute'
          id='video'
          autoPlay
          loop
          muted
          playsInline
          src='https://cdn.aid-raw.com/contents/introduce_video.mp4'></video>
        <div className='relative'>
          <div
            className='absolute top-0 left-0 z-10 w-full h-full'
            style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
            ref={videoInnerBoxOverlayRef}>
            <div
              className='z-20 flex flex-col items-start px-[96px]'
              ref={videoInnerBoxRef}>
              <div className='h-16 mt-5'>
                <img
                  className='w-[194px] mx-auto'
                  src={new_logo}
                  alt=''
                />
              </div>
              <div className='text-xl text-white'>나만의 AI 보조작가, AID</div>

              <button
                className='py-2 px-4 text-sm rounded-[40px] bg-[#F1F1F1] text-[#3E3A39] flex mt-10'
                onClick={() => moveUrl("https://aid-raw.com/login")}>
                {/* onClick={() => setIsModalOpen(true)}> */}
                내 보조작가 만나보기
                <img
                  className='ml-[10px] w-5 h-5'
                  src={arrow}
                  alt=''
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && <ContactModal setIsModalOpen={setIsModalOpen} />}
    </div>
  );
};

export default MediaBox;

import lock from "../../image/lock_squre.png";
import layer from "../../image/layer_squre.png";
import aid from "../../image/aid.png";
import logo from "../../image/logo_main 1.png";
import plus from "../../image/add_24px.png";
import equal from "../../image/equal.png";
import light from "../../image/light.png";
import upload from "../../image/file_upload.png";
import setting from "../../image/settings_24px.png";
import live from "../../image/live_box.png";
import color from "../../image/step_color.png";
import line from "../../image/step_line.png";
import layer1 from "../../image/layer1.png";
import layer2 from "../../image/layer2.png";
import layer3 from "../../image/layer3.png";
import settting_withe from "../../image/setting_white.png";

const Renewal = () => {
  return (
    <div className='max-w-[1128px] mx-auto'>
      <div className='mt-20 Introduce'>
        <div className='text-4xl'>
          최초의, 최고의{" "}
          <span className='font-bold text-main-color'>WEBTOON 전용 AI</span>
        </div>
        <div className='mt-1'>
          웹툰만을 위해 만들어진 국내 최초의 AI 서비스이자 독보적인 퀄리티의 AI
          서비스
        </div>

        <div className='flex mt-10 space-x-6'>
          <div className='flex-col p-6 space-y-4 w-[360px]'>
            <img src={lock} />
            <div className='text-2xl font-semibold text-main-color'>
              강력한 저작권 보호
            </div>
            <div>
              작가의 화풍 및 의도를 100% 표현.
              <br />
              작가의 작품들을 기반으로 이미지를 생성하여
              <br />그 어느 이미지와 다른 나만의 이미지
            </div>
            <div className='text-xl font-medium'>*저작권은 오로지 나에게만</div>
          </div>

          <div className='flex-col p-6 space-y-4 w-[360px]'>
            <img src={aid} />
            <div className='text-2xl font-semibold text-main-color'>
              편리한 AiD 세팅
            </div>
            <div>
              최소 5장의 이미지로 빠른 AI 학습 세팅
              <br />
              개인 캐릭터 이미지 업로드 혹은 <br />
              엔지니어 서포트를 통해 제작
            </div>
            <div className='text-xl font-medium'>
              *편리한 나만의 보조작가 생성
            </div>
          </div>

          <div className='flex-col p-6 space-y-4 w-[360px]'>
            <img src={layer} />
            <div className='text-2xl font-semibold text-main-color'>
              작업이 편한 결과물
            </div>
            <div>
              AI 기술로 레이어를 분리하여 제공.
              <br />
              작가의 개입 및 수정이 용이하도록
              <br />
              JPG,PNG, PSD 파일로 제공
            </div>
            <div className='text-xl font-medium tracking-tight'>
              *기존 공정과 유사한 결과물, 편리한 수정
            </div>
          </div>
        </div>
      </div>

      <div className='Characteristic mt-[120px]'>
        <div className='flex items-center'>
          <img
            src={logo}
            className='h-10'
          />
          <div className='ml-4 text-4xl'>특징</div>
        </div>
        <div className='flex items-center mt-4 space-x-6'>
          <div className='w-[360px]'>
            '일반적인' AI는 웹툰에 사용될 수 없습니다. <br />
            <span className='text-main-color'>
              웹툰에서는 그림체, 캐릭터 외모, 의상, 헤어 등이
              <br />
              일관되게 유지되어야 합니다.
            </span>
            <br />
            <br />
            AiD에서는{" "}
            <span className='text-main-color'>
              내 작품 최소 5개 이미지만으로,
            </span>
            <br />
            ​이 모든 것을 정확하게 그려내는
            <br />
            <span className='text-main-color'>'나만의 보조작가AI'</span>를 만들
            수 있습니다.
            <br />
            <br />
            복잡한 프롬프팅도, AI에 대한 이해도
            <br />
            필요하지 않습니다.
            <br />
            ​내가 만든 창작물만 있다면, 어떤 구도의 장면이든
            <br />
            손쉽게 그릴 수 있습니다!
          </div>

          <div className='flex justify-center items-center space-x-[42px]'>
            <div className='flex flex-col text-center items-center justify-center w-[168px] h-[168px] p-6 rounded-full bg-[#F1F1F1] text-base'>
              <div className='mb-2 text-xs'>작가의 데이터</div>
              <div>
                최소 5장의
                <br />
                캐릭터 이미지
              </div>
            </div>
            <img
              src={plus}
              alt=''
            />
            <div className='flex flex-col text-center items-center justify-center w-[168px] h-[168px] p-6 rounded-full bg-[#F1F1F1] text-base'>
              <img
                src={logo}
                alt=''
              />
            </div>
            <img
              src={equal}
              alt=''
            />
            <div className='flex flex-col text-center items-center justify-center w-[168px] h-[168px] p-6 rounded-full bg-main-color text-base text-white'>
              <div>
                일관되고 정확하게
                <br />
                상상/생성하는
              </div>
              <div className='text-2xl'>나만의 AI</div>
            </div>
          </div>
        </div>
      </div>

      <div className='How mt-[120px]'>
        <div className='flex items-center'>
          <img
            src={logo}
            className='h-10'
          />
          <div className='ml-4 text-4xl'>작업 방법</div>
        </div>
        <div className='mt-4'>
          AiD 서비스에 캐릭터를 등록하는 것과 더불어
          <br />
          사용할 수 있는 다양한 작업 환경들을 소개합니다.
        </div>

        <div className='flex items-center w-full p-6 mt-10 bg-[#F9F9F9] justify-between'>
          <div className='flex items-center justify-center p-2 px-6 space-x-6'>
            <div className='w-9 h-9 rounded-[6px] bg-[#EEEEEF] flex justify-center items-center'>
              <img src={light} />
            </div>
            <div className=''>
              <div className='text-xl font-semibold'>캐릭터 학습하기</div>
              <div className='mt-1'>
                새로운 캐릭터를 학습합니다.
                <br />
                전문 엔지니어의 서포트를 요청하여 학습하는{" "}
                <span className='text-main-color'>'엔지니어 서포트'</span>
                서비스와
                <br />
                개인적으로 데이터를 업로드하여 학습하는{" "}
                <span className='text-main-color'>'개인 이미지 업로드'</span>
                서비스가 있습니다.
              </div>
            </div>
          </div>

          <div className='flex space-x-6'>
            <div className='flex flex-col justify-center items-center bg-[#F5F5F5] p-6 w-[168px] h-[100px]'>
              <img
                src={setting}
                alt=''
              />
              <div className='mt-3'>엔지니어 서포트</div>
            </div>
            <div className='flex flex-col justify-center items-center bg-[#F5F5F5] p-6 w-[168px] h-[100px]'>
              <img
                src={upload}
                alt=''
              />
              <div className='mt-3'>개인 이미지 업로드</div>
            </div>
          </div>
        </div>

        <div className='flex mt-6 space-x-6'>
          <div className='w-[360px] rounded-[6px] p-12 bg-[#F9F9F9]'>
            <div className='flex items-center'>
              <div className='w-9 h-9 p-[6px] bg-[#EEEEEF] flex items-center'>
                <img
                  src={live}
                  alt=''
                />
              </div>
              <div className='ml-6'>
                <div className='text-xl'>라이브 스케치</div>
                <div className='mt-1 text-sm'>
                  실시간으로 스케치를 생성합니다.
                </div>
              </div>
            </div>

            <div className='w-[264px] h-[264px] rounded-[6px] bg-white object-cover'>
              <img
                className='mt-6'
                src={layer1}
                alt=''
              />
            </div>
          </div>
          <div className='w-[360px] rounded-[6px] p-12 bg-[#F9F9F9]'>
            <div className='flex items-center'>
              <div className='w-9 h-9 p-[6px] bg-[#EEEEEF] flex items-center'>
                <img
                  src={line}
                  alt=''
                />
              </div>
              <div className='ml-6'>
                <div className='text-xl'>펜터치 생성하기</div>
                <div className='mt-1 text-sm'>
                  캐릭터의 펜터치를 생성합니다.
                </div>
              </div>
            </div>

            <div className='w-[264px] h-[264px] rounded-[6px] bg-white object-cover'>
              <img
                className='mt-6'
                src={layer2}
                alt=''
              />
            </div>
          </div>
          <div className='w-[360px] rounded-[6px] p-12 bg-[#F9F9F9]'>
            <div className='flex items-center'>
              <div className='w-9 h-9 p-[6px] bg-[#EEEEEF] flex items-center'>
                <img
                  src={color}
                  alt=''
                />
              </div>
              <div className='ml-6'>
                <div className='text-xl'>채색 생성하기</div>
                <div className='mt-1 text-sm'>선화에 채색을 입힙니다.</div>
              </div>
            </div>

            <div className='w-[264px] h-[264px] rounded-[6px] bg-white object-cover'>
              <img
                className='mt-6'
                src={layer3}
                alt=''
              />
            </div>
          </div>
        </div>
      </div>

      <div className='Plan mt-[120px] mb-20'>
        <div className='flex items-center'>
          <img
            src={logo}
            className='h-10'
          />
          <div className='ml-4 text-4xl'>플랜 정책</div>
        </div>
        <div className='mt-4'>
          AiD는 더 나은 서비스를 제공하고자 이와 같은 가격정책을 두고 있습니다.
        </div>

        <div className='flex mt-10'>
          <div className='flex'>
            <div className='relative w-[264px] h-[373px] bg-[#F1F1F1] p-5 rounded-[6px]'>
              <div className='text-xl'>Starter Plan</div>
              <div className='mt-4 text-sm'>튜토리얼만 제공</div>
              <div className='absolute text-3xl bottom-5 right-5'>0원/월</div>
            </div>

            <div className='flex flex-col pl-3 ml-3 border-l-2 border-gray-300 border-dotted'>
              <div className='flex'>
                <div className='w-[264px] h-[202px] rounded-[6px] relative bg-[#F1F1F1] p-5'>
                  <div className='text-xl'>Standard Plan</div>
                  <div className='mt-4 text-sm'>
                    100 aid 제한
                    <br />
                    펜터치, 채색 기능 제공
                  </div>
                  <div className='absolute flex bottom-5 right-5'>
                    <div className='text-base text-main-color'>*</div>
                    <div className='text-3xl'>27,000원/월</div>
                  </div>
                </div>
                <div className='pl-3 ml-3 border-l-2 border-gray-300 border-dotted'>
                  <div className='w-[264px] h-[202px] rounded-[6px] relative bg-[#F1F1F1] p-5 border-main-color border-[2px]'>
                    <div className='relative text-xl'>
                      Premium Plan{" "}
                      <span className='absolute top-0 text-base font-bold right-7 text-main-color'>
                        BEST!
                      </span>
                    </div>
                    <div className='mt-4 text-sm'>
                      500 aid 제한
                      <br />
                      펜터치, 채색 기능 제공
                      <br />
                      라이브 스케치 기능 제공
                    </div>
                    <div className='absolute flex bottom-5 right-5'>
                      <div className='text-base text-main-color'>*</div>
                      <div className='text-3xl'>135,000원/월</div>
                    </div>
                  </div>
                </div>
                <div className='pl-3 ml-3 border-l-2 border-gray-300 border-dotted'>
                  <div className='w-[264px] h-[202px] rounded-[6px] relative bg-[#F1F1F1] p-5'>
                    <div className='text-xl'>Enterprise Plan</div>
                    <div className='mt-4 text-sm'>
                      1 GPU 전용회선 <br />
                      이용량 조정
                    </div>
                    <div className='absolute flex bottom-5 right-5'>
                      <div className='text-3xl'>별도 문의</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='w-full rounded-[6px] relative bg-[#F1F1F1] p-5 mt-6 flex justify-between'>
                <div>
                  <div className='flex items-center'>
                    <div className='w-[32px] h-[32px] flex justify-center items-center bg-[#3D3939] rounded-[6px] mr-2'>
                      <img
                        src={settting_withe}
                        alt=''
                      />
                    </div>
                    <div className='relative w-[400px] text-xl'>
                      엔지니어 서포트{" "}
                      <span className='pl-1 text-base'>캐릭터 학습</span>
                      <span className='absolute top-0 text-base font-bold right-[136px] text-main-color'>
                        NEW!
                      </span>
                    </div>
                  </div>
                  <div className='relative mt-4 text-sm'>
                    전문 엔지니어의 도움을 받아 캐릭터를 학습합니다.
                    <br />
                    별도의 파일 정리 없이 PSD, CLIP 파일만 있어도 바로 캐릭터를
                    <br />
                    생성할 수 있어요.{" "}
                    <span className='absolute bottom-0 text-xs right-[80px]'>
                      *영업일 기준 최대 3일의 시간이 소요될 수 있습니다.
                    </span>
                  </div>
                </div>

                <div className='flex items-end bottom-5 right-5'>
                  <div className='text-base text-main-color'>*</div>
                  <div className='text-3xl'>40,000원/캐릭터</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-end mt-2 text-main-color'>*VAT 별도</div>
      </div>
    </div>
  );
};

export default Renewal;

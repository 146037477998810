import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContactModal from "../Body/ContactModal";

// import logo from "../../image/aid-row.png";
import logo from "../../image/logo_main 1.png";

const Header = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const moveUrl = (url) => {
    // 새탭 열기로 사이트 이동
    window.open(url);
  };

  return (
    <div className='Header'>
      <div className='max-w-[1128px] mx-auto flex justify-between items-center py-12 h-[120px]'>
        <div className='w-auto h-10'>
          <img
            src={logo}
            className='h-full'
          />
        </div>

        <div className='flex space-x-3'>
          <button
            className='px-4 py-2 text-white bg-main-color rounded-[40px] text-sm'
            onClick={() => moveUrl("https://aid-raw.com/login")}>
            {/* onClick={() => setIsModalOpen(true)}> */}
            생성하기
          </button>
          {/* <button
            className='bg-aid-orange-200 text-aid-orange-500 border border-aid-orange-500 rounded-[12px] py-[10px] px-[24px]'
            onClick={() => moveUrl("https://studio.aid-raw.com/login")}>
            로그인
          </button>
          <button
            className='bg-gray-100 border border-gray-300 rounded-[12px] py-[10px] px-[24px]'
            onClick={() => moveUrl("https://studio.aid-raw.com/signup")}>
            회원가입
          </button> */}
        </div>
      </div>

      {isModalOpen && <ContactModal setIsModalOpen={setIsModalOpen} />}
    </div>
  );
};

export default Header;

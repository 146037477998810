import { useState, useEffect } from "react";
import axios from "axios";
import close from "../../image/close_black.png";

const ContactModal = ({ setIsModalOpen }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [isAgree, setIsAgree] = useState(false);
  const [valid, setValid] = useState(false);

  const sendAxios = () => {
    console.log(name, email, phone);

    axios
      .post(
        "https://api-studio.aid-raw.com/api/contact/welcome",
        {
          name: name,
          email: email,
          phone: phone,
          contents: "서비스 오픈 알림 신청",
        },
        {
          headers: {
            Referer: "https://studio.aid-raw.com", // 여기에 Referer URL을 설정
          },
        }
      )
      .then((res) => {
        console.log(res);
        alert("알림 신청이 완료되었습니다.");
        setIsModalOpen(false);
      });
  };

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        setIsModalOpen(false);
      }
    });

    return () => {
      window.removeEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          setIsModalOpen(false);
        }
      });
    };
  }, []);

  useEffect(() => {
    console.log("name", name);
    console.log("email", email);
    console.log("phone", phone);
    console.log("isAgree", isAgree);
    setValid(name !== "" && email !== "" && phone !== "" && isAgree);
  }, [name, email, phone, isAgree]);

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60'>
      <div className='flex flex-col justify-center w-[504px] items-center p-8 bg-gray-100 shadow-lg rounded-lg relative'>
        <div className='flex flex-col items-center justify-center gap-2'>
          <div className='text-2xl font-bold'>안내 사항</div>
          <div className='text-[16px] text-center mt-4'>
            현재 서버 증설과 신규 기능을 출시를 위한 서비스 점검 중으로,
            <br />
            <span className='font-semibold'>
              로그인 및 회원가입이 일시적으로 제한
            </span>
            되고 있습니다.
          </div>

          <div className='text-[16px] text-center mt-1'>
            · 점검 완료 예정 기간 :{" "}
            <span className='font-semibold'>10월 18일 ~ 10월 31일</span>
          </div>

          <div className='text-[16px] text-center mt-1'>
            아래 양식을 통해 연락처를 남겨주시면,
            <br />
            <span className='italic font-semibold'>서비스 오픈 알림</span>을
            받아보실 수 있습니다.
          </div>
          <div className='text-[12px] text-center mt-1 font-semibold'>
            · 경기국제웹툰페어를 통해 접속하신 고객님의 경우, 메모를 통해 해당
            내용을 남겨주시면
            <br />
            서비스 오픈 시 추가 혜택을 제공드릴 예정입니다!
          </div>

          <hr className='w-full mt-4 border-gray-300' />

          <div className='flex h-auto space-x-1'>
            <div className='flex flex-col justify-end space-y-1 text-end'>
              <label className='h-[24px] text-[14px] font-semibold'>이름</label>
              <label className='h-[24px] text-[14px] font-semibold'>
                이메일
              </label>
              <label className='h-[24px] text-[14px] font-semibold'>
                핸드폰 번호
              </label>
            </div>

            <div className='flex flex-col justify-end space-y-1'>
              <input
                className='ml-2 border border-gray-300 rounded-[4px] px-1 focus:border-main-color'
                type='text'
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              <input
                className='ml-2 border border-gray-300 rounded-[4px] px-1 focus:border-main-color'
                type='text'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <input
                className='ml-2 border border-gray-300 rounded-[4px] px-1 focus:border-main-color'
                type='text'
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />
            </div>
          </div>

          <div className='flex flex-col justify-center mt-2'>
            <div className='flex items-center justify-center space-x-1'>
              <label className='text-[14px] font-semibold'>
                서비스 오픈 알림 동의
              </label>
              <input
                className='ml-2 border border-gray-300 rounded-[4px] w-[16px] h-[16px]'
                type='checkbox'
                checked={isAgree}
                onChange={(e) => setIsAgree((prev) => !prev)}
              />
            </div>

            <button
              className={`w-full h-[33px] bg-red200 rounded-full mx-auto mt-1 ${
                valid ? "bg-main-color text-white" : "bg-gray-300"
              }`}
              disabled={!valid}
              onClick={sendAxios}>
              알림 신청하기
            </button>
          </div>
        </div>

        <img
          className='absolute w-6 h-6 cursor-pointer top-6 right-6'
          src={close}
          alt=''
          onClick={() => setIsModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default ContactModal;
